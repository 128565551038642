.page_settings .list_annual_goal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}
.page_settings .list_annual_goal .show_price{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}
.page_settings .list_annual_goal .show_price .icons{
    position: relative;
    top: 2px;
}
.page_settings .list_annual_goal .list_month{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}
.page_settings .list_annual_goal .list_month .Comp_Input{
    flex-grow: 1;
}
.page_settings .list_annual_goal .list_month .Comp_Input .price{
    width: calc(100% - 24px);
}
.page_settings .list_annual_goal .year{
    width: 60px;
    text-align: center;
}
.page_settings .list_annual_goal .price_all{
    width: 180px;
    text-align: center;
}
.page_settings .list_annual_goal .price{
    width: 100px;
    text-align: center;
}
.page_settings .div_show_btn .email{
    width: 300px;
}

@media only screen and (max-width: 800px) {
    .page_settings .list_annual_goal .list_month,
    .page_settings .list_annual_goal .show_price,
    .page_settings .div_show_btn{
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 480px) {
    .page_settings .div_show_btn .email{
        width: calc(100% - 22px);
    }
    .page_settings .div_show_btn .Comp_Input{
        flex-grow: 1;
    }
}