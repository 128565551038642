.Comp_Select{
    width: max-content;
    min-width: 236px;
    position: relative;
    margin-top: 8px;
    color: #424242;
    text-align: left;
}
.Comp_Select_flex_grow {
    flex-grow: 1;
}
.restricted{
    width: 90px;
    min-width: 90px;
}
.type_data{
    width: 110px;
    min-width: 110px;
}
.cover_type{
    width: 130px;
    min-width: 130px;
}
.screen, .budget_billed{
    width: 146px;
    min-width: 146px;
}
.budget_signature, .crm_signature{
    width: 240px;
    min-width: 240px;
}
.budget_status, .crm_status{
    width: 220px;
    min-width: 220px;
}
.budget_type, .crm_type{
    width: 184px;
    min-width: 184px;
}
.chance_close{
    width: 152px;
    min-width: 152px;
}
.how_met{
    width: 260px;
    min-width: 260px;
}
.type_currency{
    width: 124px;
    min-width: 124px;
}
.abbreviated_total{
    width: 134px;
    min-width: 134px;
}
.type_discount{
    width: 160px;
    min-width: 160px;
}
.type_text{
    width: 360px;
    min-width: 360px;
}
.type_service{
    width: 116px;
    min-width: 116px;
}
.became_proposal{
    width: 126px;
    min-width: 126px;
}

.Comp_Select .div_data_input{    
    height: 37px;
    border: 2px solid #ebebeb;
    font-size: 14px;
    padding: 0px 10px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
    border-radius: 25px;

    position: relative;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
}
.Comp_Select .div_data_input .name{
    font-size: 12px;
    position: absolute;
    top: -13px;
    left: 16px;
    padding: 0px 4px;
    background: #ffffff;
}

.Comp_Select .div_data_input .show_input{
    flex-grow: 1;
}
.Comp_Select .div_data_input .show_input input{
    width: 100%;
    border: none;
    font-size: 14px;
    padding: 10px 0px;
    font-family: 'inter';
    outline: none;
    background-color: #ffffff;
    
    border-radius: 25px;
}

.Comp_Select .div_data_input .show_arrow .icons{
    width: 16px;
    height: auto;
}
.Comp_Select .div_data_input .show_arrow .icon_bottom{
    transform: rotate(90deg);
}
.Comp_Select .show_list{        
    width: calc(100% - 4px);
    font-size: 14px;
    background-color: #ffffff;
    
    border-radius: 10px;

    overflow-y: auto;
    max-height: 0;
    transition: max-height .5s ease-out;
    z-index: 999;

    position: absolute;
    top: 44px;
}
.Comp_Select .show_list .opt_select{
    padding: 10px;
    cursor: pointer;
}
.Comp_Select .show_list .opt_select:hover{
    background-color: rgb(173 250 6 / 10%);
}
.Comp_Select .show_list .opt_select_selected,
.Comp_Select .show_list .opt_select_selected:hover{
    background-color: rgb(173 250 6 / 40%);
}