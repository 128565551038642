.Comp_AltOrderList .icons{
    width: 16px;
    height: auto;
}
.Comp_AltOrderList .icon_top{
    cursor: pointer;
    transform: rotate(-90deg);
}
.Comp_AltOrderList .icon_bottom{
    cursor: pointer;
    transform: rotate(90deg);
}