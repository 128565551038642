.PopUp .UpdateSale {
    width: 600px;
    margin: 0% auto;
}
.PopUp .UpdateSale .div_input{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
}

@media only screen and (max-width: 480px) {
    .PopUp .UpdateSale{
        width: calc(80% - 26px);
    }
    .PopUp .UpdateSale .div_input{
        flex-wrap: wrap;
    }
}