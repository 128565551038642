.Comp_InputFile{
    position: relative;
    margin-top: 8px;
    color: #424242;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Comp_InputFile input[type="file"]{
    display: none;
}
.Comp_InputFile label{
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-radius: 25px;
    background: #475037;
    color: #ffffff;
    height: 41px;
    padding: 0px 14px;
    cursor: pointer;
}
.Comp_InputFile .label_active{
    background: #b9a42f;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.Comp_InputFile .show_file_{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.Comp_InputFile .div_remuve_border{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.Comp_InputFile .div_remuve{
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    height: 41px;
    background: #386c5f;
    padding: 0px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.Comp_InputFile .show_img{
    padding-left: 6px;
    cursor: pointer;
}
.Comp_InputFile .show_img .img_thumbnail{
    width: auto;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}