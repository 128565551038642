.PopUp .Confirmation{
    width: 400px;
    margin: 0 auto;
}
.PopUp .Confirmation .title{
    justify-content: center;
    line-break: anywhere;
}
.PopUp .Confirmation .div_confirmation{
    flex-direction: row !important;
    color: #ffffff;
}
.PopUp .Confirmation .alert{
    color: #f00000;
    margin-bottom: 20px;
}
.PopUp .Confirmation .alert .attention{
    font-size: 18px;
    font-weight: 600;
}
.PopUp .Confirmation .title_name_delete{
    padding-bottom: 20px;
}
.PopUp .Confirmation .div_opt_delete{
    flex-grow: 1;
}