@media only screen and (max-width: 800px) {
    .page_services .td_1{
        display: none;
    }
    .page_services .td_2{
        width: auto !important;
    }
}
@media only screen and (max-width: 630px) {
    .page_services .td_2{
        display: none;
    }
}
@media only screen and (max-width: 484px) {
    .page_services .td_1, .page_services .td_2, .page_services .td_3{
        display: none;
    }
}