.page_portfolio {
}

.page_portfolio .list_portfolio{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}
.page_portfolio .list_portfolio .no_data{
    text-align: center;
}
.page_portfolio .list_portfolio .show_portfolio{
    width: 265px;
    height: 163px;
    border-radius: 25px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    box-shadow: 0 .3rem 1rem 0 rgba(0, 0, 0, .5);
    cursor: pointer;
}
.page_portfolio .list_portfolio .show_portfolio .lock{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 30%);
    border-radius: 25px;
    
    display: flex;
    align-items: center;
    justify-content: center;
}
.page_portfolio .list_portfolio .show_portfolio:hover .lock {
    display: none;
}
.page_portfolio .list_portfolio .show_portfolio .div_show_inf{   
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 30%);
    border-radius: 25px;
     
    display: none;
}
.page_portfolio .list_portfolio .show_portfolio:hover .div_show_inf{
    display: block;
}
.page_portfolio .list_portfolio .show_portfolio .div_icons{
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding: 16px 10px 0px;
    transition: 0.3 ease-in-out;
    position: relative;
}
.page_portfolio .list_portfolio .show_portfolio:hover .div_icons{
    display: flex;
}
.page_portfolio .list_portfolio .show_portfolio .div_icons .opt_click{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}
.page_portfolio .list_portfolio .show_portfolio .name_project{
    width: calc(100% - 32px);
    height: 38px;
    padding: 0px 16px;
    font-size: 12px;
    color: #ffffff;
    background-color: rgb(0 0 0 / 40%);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;

    position: absolute;
    bottom: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.page_portfolio .list_portfolio .show_portfolio:hover .name_project{
    display: none;
}
.page_portfolio .list_portfolio .show_portfolio .div_eye{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.page_portfolio .list_portfolio .show_portfolio:hover .div_eye{
    display: flex;
}

@media only screen and (max-width: 800px) {
}
@media only screen and (max-width: 630px) {
}
@media only screen and (max-width: 484px) {
}