.PopUp .CheckedData{
    width: 360px;
    margin: 2% auto;
}
.PopUp .CheckedData .div_data{
    padding-top: 0px;
}
.PopUp .CheckedData .icons_return{
    width: 200px;
    height: 200px;
    cursor: default;
}
.PopUp .CheckedData .title_return{
    font-size: 16px;
    font-family: 'Inter';

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.PopUp .CheckedData .div_confirmation{
    width: 100%;
    flex-direction: row !important;
    color: #ffffff;
}
.PopUp .CheckedData .div_confirmation div{
    flex-grow: 1;
}
.PopUp .CheckedData .close_return{
    background-color: #43a047;
    color: #ffffff;
    width: 100%;
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'inter';
    cursor: pointer;
    margin-top: 10px;
}

.PopUp .CheckedData .div_inf_popup{
    border: 2px solid #ebebeb;
    width: calc(100% - 24px);
    padding: 10px;
    border-radius: 25px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.PopUp .CheckedData .div_btn_add{
    top: auto;
}

@media only screen and (max-width: 570px) {
    .PopUp .CheckedData {
        width: 90%;
    }
}