.page_crm .list_status{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 20px;
}
.page_crm .list_status .div_status{
    min-width: 180px;
}
.page_crm .list_status .div_status .name_status{
    text-align: center;
    color: #ffffff;
    padding: 6px 20px;
    white-space: nowrap;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.page_crm .list_status .div_status .show_data_status{
    padding: 6px 20px;
    background-color: #ffffff;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}