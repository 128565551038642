.Comp_Input{
    position: relative;
    margin-top: 8px;
    color: #424242;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}
.input_all{
    width: 100%;
}
.Comp_Input input{
    border: 2px solid #ebebeb;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
    
    border-radius: 25px;
}
.Comp_Input .name{
    font-size: 12px;
    position: absolute;
    top: -10px;
    left: 16px;
    padding: 0px 4px;
    background: #ffffff;
}