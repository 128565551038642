.inf_dashboard{
    width: 100%;
    gap: 30px;
    color: #000000;
}

/* year */
.inf_dashboard .div_show_year{
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    padding-bottom: 10px;
    margin-bottom: 40px;
    border-bottom: 1px solid #D8D8D8;
}
.inf_dashboard .div_show_year .arrow .left{
    transform: rotate(180deg);
    transition: .5s;
}
.inf_dashboard .div_show_year .show_year{
    font-size: 26px;
    font-weight: 500;
    font-family: 'bebas neue';
    line-height: normal;
}
/* end */

/* btn */
.inf_dashboard .list_data{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}
.inf_dashboard .list_data .div_inf_data{
    width: 262px;
    min-width: 262px;
    max-width: 262px;
    height: auto;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: 1px solid #D8D8D8;

    border-radius: 25px;
}
.inf_dashboard .list_data .div_inf_data .qtd{
    width: calc(100% - 40px);
    height: 42px;
    font-size: 42px;
    font-family: 'inter';
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.inf_dashboard .list_data .div_inf_data .show_name{
    font-size: 14px;
    text-align: center;
}
.inf_dashboard .list_data .div_inf_data .title{
    width: 100%;
    height: 32px;
    font-family: 'inter';
    font-size: 14px;
    color: #ffffff;
    line-height: 12px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-top: 1px solid;
    background: #000000;
    
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
}
/* end */

/* graffic */
.apexcharts-menu.apexcharts-menu-open{
    width: 120px;
}
.inf_dashboard .list_opt_grafic{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    margin-top: 40px;
    width: calc(100% - 40px);
    background: #ffffff;
    padding: 20px;
    letter-spacing: 1px !important;

    border-radius: 25px;
}
.inf_dashboard .list_opt_grafic .title_block{
    width: 100%;
    text-align: center;
    font-family: 'bebas neue';
    font-size: 26px;
    font-weight: 500;
    color: #6c757d;
    line-height: normal;
}
.inf_dashboard .list_opt_grafic .list_data .div_inf_data{
    flex-grow: 1;
    max-width: initial;
}
.inf_dashboard .list_opt_grafic .div_graffic{
    width: 100%;
    height: auto;
}

.inf_dashboard .list_graffic_pizza{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    margin-top: 30px;
    letter-spacing: 1px !important;
}
.inf_dashboard .list_graffic_pizza .graffic_pizza{
    width: 48%;
    height: auto;
    flex-grow: 1;
    background: #ffffff;
    padding: 20px;

    border-radius: 25px;
}
/* end */

@media only screen and (max-width: 980px) {
    .inf_dashboard .list_data .div_inf_data{
        width: 40%;
        max-width: initial;
        min-width: 270px;
        flex-grow: 1;
    }
}