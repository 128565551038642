.page_access_dash .show_page_data table th{
    padding: 0px 16px 10px;
}
.page_access_dash .div_btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #000000;
    font-size: 12px;
    border-radius: 25px;
}
.page_access_dash .div_btn div{
    padding: 6px 5px;
}
.page_access_dash .div_btn div:nth-child(4){
    padding-left: 0px;
}