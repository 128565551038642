.input_search{    
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}
.input_search .icons_search{
    position: absolute;
    top: 12px;
    left: 8px;
    width: 22px;
    height: 22px;
    transition: 0.5s;
}
.input_search .search{
    width: 180px;
    height: 42px;
    color: #9BABC5;
    background-color: transparent;
    border: 1px solid #ADFA06;
    border-radius: 20px;
    padding: 0px 10px 0px 34px;
    outline: none;
}
.input_search ::placeholder {
    color: #9BABC5;
}