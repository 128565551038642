.page_portfolio .div_color{
    top: 4px;
}
.page_portfolio .title_video{
    width: 220px;
}
.page_portfolio .div_project{
    border: none;
    padding: 0;
    padding-top: 16px;
}