.PopUp .CheckedCrm {
    width: 660px;
    margin: 0% auto;
}
.PopUp .CheckedCrm .div_input{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.PopUp .CheckedCrm .div_btn_add{
    flex-grow: 1;
}
.PopUp .CheckedCrm .div_btn_add .show_opt_btn {
    background-color: transparent;
    border: 1px solid #ADFA06;
}

@media only screen and (max-width: 480px) {
    .PopUp .CheckedCrm{
        width: calc(80% - 26px);
    }
}