.PopUp .RecoverEmail{
    width: 500px;
    margin: 0;
}
.PopUp .RecoverEmail .button_profile{
    width: -webkit-fill-available;
}
.PopUp .RecoverEmail .align_profile{
    text-align: left;
}

.PopUp .RecoverEmail input{
    width: calc(100% - 34px);
    background: rgba(0, 0, 0, 0);
    border: 1px solid #2d3c53;
    border-radius: 25px;
    box-shadow: inset 0 0 10px rgb(0 95 161 / 10%);
    color: #2d3c53;
    padding: 16px;
    
    outline: none;
}
.PopUp .RecoverEmail input::placeholder {
    color: #3d495a;
    opacity: 0.4;
}

.PopUp .RecoverEmail .span_name_textarea{
    position: absolute;
    left: 8px;
    top: -3px;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    background-color: #ffffff;
    padding: 0 10px;
}
.PopUp .RecoverEmail .pass{
    width: 120px;
}
.PopUp .RecoverEmail .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .RecoverEmail .div_input{
    width: auto;
}
.PopUp .RecoverEmail .div_btn{
    width: 100%;
}
.PopUp .RecoverEmail .msg_return{
    margin-top: 18px;
}
.PopUp .RecoverEmail .btn_save{
    width: 100%;
    background-color: #2d3c53;
    border: none;
    cursor: pointer;
    color: #ededed;
    font-size: 14px;
    font-family: 'Inter';
    border-radius: 14px;

    justify-content: center;
}
.PopUp .RecoverEmail .btn_save:hover{
    background-color: #3d495a;
}
.PopUp .RecoverEmail .exit{
    width: auto;
    height: 40px;
    padding: 0px 30px;
    font-size: 14px;
    background-color: #e51d4d;
    font-family: 'Inter';
    border-radius: 25px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .RecoverEmail .exit:hover{
    background-color: #a70b2f;
}

@media only screen and (max-width: 570px) {
    .PopUp .RecoverEmail {
        width: 90%;
    }
}