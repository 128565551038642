.Comp_Textarea {
    width: 100%;
    flex-grow: 1;
    position: relative;
    margin-top: 8px;
    color: #424242;
}
.Comp_Textarea .textarea {
    width: 100%;
    border: 2px solid #ebebeb;
    font-size: 14px;
    padding: 8px;
    font-family: 'inter';
    outline: none;
    resize: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
    min-height: 52px;
}
.Comp_Textarea .name {
    font-size: 12px;
    position: absolute;
    top: -10px;
    left: 16px;
    padding: 0px 4px;
    background: #ffffff;
}

.jodit-status-bar, 
.jodit-ui-button_insert_as_text, 
.jodit-ui-button_keep, 
.jodit-ui-button_text-icons_true{
    display: none !important;
}
.jodit-ui-button_insert_only_text {
    background-color: #7c9f06 !important;
    color: #fff !important;
    display: block !important;
}
.jodit-ui-button_insert_only_text > span{
    display: none !important;
}
.jodit-ui-button_insert_only_text::after{
    content: "Colar somente o texto";
}
.jodit-dialog__header-title{
    display: none !important;
}
.jodit-dialog__header{
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
}
.jodit-dialog__resizer{
    display: none !important;
}
/* .jodit-dialog__header-title:after{
    content: "Copiar texto";
} */
.jodit-dialog__content {
    display: none !important;
}
.jodit-dialog_footer_true .jodit-dialog__footer{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.jodit-container:not(.jodit_inline){
    border: 2px solid #ebebeb !important;
    background-color: #ffffff !important;
    border-radius: 25px !important;
    padding: 0px;
}
.jodit-container:not(.jodit_inline) .jodit-workplace,
.jodit-toolbar__box:not(:empty):not(:empty){
    background-color: transparent !important;
}
.jodit-add-new-line{
    display: none !important;
}