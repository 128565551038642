.page_access_dash .div_img_user{
    width: 34px;
    height: 34px;
    border: 2px solid #C3C3C3;
    border-radius: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 2px;
    position: relative;
    cursor: pointer;
}
.page_access_dash .div_img_user .img_user{
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 34px;
}
@media only screen and (max-width: 800px) {
    .page_access_dash .td_1{
        display: none;
    }
    .page_access_dash .td_2{
        width: auto !important;
    }
}
@media only screen and (max-width: 630px) {
    .page_access_dash .td_2{
        display: none;
    }
}
@media only screen and (max-width: 484px) {
    .page_access_dash .td_1, .page_access_dash .td_2, .page_access_dash .td_3{
        display: none;
    }
}