.PopUp .Color{
    width: 264px;
    margin: 0 auto;
    color: #000000;
    padding: 21px;
}
.PopUp .Color input{    
    border: 2px solid #ebebeb;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
}
.PopUp .Color .InputColor{ 
    width: calc(100% - 22px);
    overflow: hidden;
    padding: 11px;
}
.PopUp .Color .InputColor .div_saturation{    
    width: 100%;
    height: 146px;
    position: relative;
    margin-bottom: 20px;
}
.PopUp .Color .InputColor .w-color-saturation{
    width: 100% !important;
    height: 146px !important;
    border-radius: 25px !important;
}
.PopUp .Color .InputColor .div_hue{
    position: relative;
    margin-bottom: 16px;
}
.PopUp .Color .InputColor .hue-picker{
    width: 100% !important;
}
.PopUp .Color .InputColor .div_input{    
    position: relative;
    margin-bottom: 10px;
}
.PopUp .Color .InputColor .color_select{
    width: 33px;
    height: 33px;
    position: absolute;
    top: 4px;
    right: 4px;
}
.PopUp .Color .InputColor .color_hex{
    width: 100%;
    display: flex;
    align-items: center;
}
.PopUp .Color .InputColor .color_rgb{
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
}
.PopUp .Color .InputColor .color_rgb_div{
    height: 16px;   
    width: -webkit-fill-available;
    margin-top: 4px;
    flex-grow: 1;
    border-radius: 25px;
}
.PopUp .Color .InputColor .input_rgb{
    width: 30px;
    height: 30px;
    padding: 0px 6px;
    text-align: center;
    border-radius: 25px;
}
.PopUp .Color .InputColor .svg_icon{
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.PopUp .Color .InputColor .div_select_type{
    text-align: left;
    display: flex;
    margin-bottom: 10px;
}
.PopUp .Color .InputColor .div_select_type .arrow_right{
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .Color .InputColor .type{
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .Color .InputColor .div_button{
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}
.PopUp .Color .InputColor .cancel{
    cursor: pointer;
    color: #000000;
}
.PopUp .Color .InputColor .add_color{
    cursor: pointer;
    color: #000000;
    padding: 10px 20px;
    background: #ADFA06;
    border-radius: 25px;
}
.PopUp .Color .hue-horizontal{
    border-radius: 25px !important;
}