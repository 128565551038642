.Comp_Topic{    
    width: 100%;
    height: auto;
}
.Comp_Topic .new_block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E2E8F0;
    flex-wrap: wrap;
    gap: 10px;
    min-height: 40px;
}
.Comp_Topic .btn_end{
    border-bottom: 0;
    border-top: 1px solid #E2E8F0;
    margin-top: 28px;
    padding-top: 26px;
}
.Comp_Topic .new_block .icons{
    width: 10px;
    height: 10px;
}
.Comp_Topic .new_block .div_show_segund{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.Comp_Topic .new_block .title_page{
    font-size: 26px;
    font-weight: 500;
    font-family: 'bebas neue';
    line-height: normal;

    color: #000000;
     
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.Comp_Topic .new_block .show_sub{
    flex-direction: column;
    gap: 0;
}
.Comp_Topic .new_block .show_sub div{
    line-height: 18px;
}
.Comp_Topic .new_block .title_page .sub_code{
    font-size: 19px;
    position: relative;
    top: 4px;
}
.Comp_Topic .new_block .title_page .arrow_left{
    transform: rotate(180deg);
    position: relative;
    top: -1px;
}
.Comp_Topic .new_block .title_page span{
    font-size: 14px;
}
.Comp_Topic .new_block .list_opt_alt_page{    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    color: #000000;
    border: none;
}
.Comp_Topic .new_block .list_opt_alt_page .div_add_new_data{
    position: relative;
    width: auto;
    text-align: center;
    height: 42px;
    cursor: pointer;
    color: #000000;
    border: none;
    padding: 0 16px;
    font-size: 14px;
    font-family: 'Poppins';
    background-color: #ADFA06;
    justify-content: space-between;
    border-radius: 20px;   
    
    display: flex;
    align-items: center;
    gap: 10px;
}
.Comp_Topic .new_block .list_opt_alt_page .div_add_new_data .new_data_add{
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-in-out;
}
.Comp_Topic .new_block .list_opt_alt_page .div_add_new_data .new_data_add .icon_save{
    width: 22px;
    height: 22px;
}
.Comp_Topic .new_block .list_opt_alt_page .div_add_new_data .name_btn_add{
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-in-out;
    overflow: hidden;
}

@media only screen and (max-width: 800px) {
    .Comp_Topic .new_block .title_page{
        font-size: 20px;
        font-weight: 500;
    }
}