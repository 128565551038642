.page_budget_details .show_page_data table th{
    padding: 0px 16px 10px;
}

.page_budget_details .type_title .add_new_inf{
    padding: 6px 20px;
}
.page_budget_details .type_title .remuve_padding{
    padding: 10px 20px;
}
.page_budget_details .div_color{
    width: 107px;
    top: 5px;
}
.page_budget_details .type_title .not_icon{
    padding: 10px 20px;
}

.page_budget_details .div_remuve_border{
    width: calc(100% - 40px);
    border: none;
    padding: 0px 20px 20px;
    margin-top: 0px;
}
.page_budget_details .div_show_btn .flex_direction_one{
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 20px 20px 0px;

    border: 2px solid #ebebeb;
    border-radius: 25px;
}
.page_budget_details .div_show_btn .flex_direction_one .list_input_data{
    width: 100%;
    padding: 0px 0px 20px;
}
.page_budget_details .div_show_btn .flex_direction_one .list_service{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0px;

    border: 1px solid #ebebeb;
    width: calc(100% - 40px);
    padding: 20px;
}

.page_budget_details .flex_direction .list_input_data{
    width: calc(100% - 40px);
}