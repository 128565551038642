html {
    scroll-behavior: smooth;
}
body, html {
    height: 100%;
}
body {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #6c757d;
    font-family: 'Poppins';
    background-color: #fff;
    overflow-x: hidden;
    letter-spacing: 0px;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}
p{
    padding: 0px;
    margin: 0;
}
svg {
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    cursor: pointer;
}
#root {
    width: 100%;
    height: 100%;
}
.container{
    max-width: 1200px;
    margin: 0% auto;
}
.icons {
    width: 24px;
    height: 24px;
    display: block;
}
.list_opt {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    gap: 6px;
}
ul {
    list-style: disc !important;
    padding-left: 18px;
    margin: 0px;
    padding: 0px;
}
ul li {
    margin-bottom: 20px;
}
.space_div {
    flex-grow: 1;
}
.color_td{
    width: 30px;
    height: 20px;
    display: flex;
    cursor: pointer;
}
.div_color{
    width: 100px;
    height: 33px;
    border: 2px solid #ebebeb;

    position: relative;
    top: 8px;
    padding: 2px;
    border-radius: 25px;
}
.div_color .color{
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 25px;
}
.div_color .legend{
    font-size: 12px;
    position: absolute;
    top: -13px;
    left: 10.4px;
    padding: 0px 4px;
    color: #424242;
    background: #ffffff;
}
.div_video {
    width: 100%;
    height: auto;
    aspect-ratio: 1.777;
    border: none;
}
::placeholder {
    color: #a5a5a5;
    font-size: 14px;
}
.page_released{
    display: flex;
    width: max-content;
    flex-grow: 1;
    gap: 6px;
    background-color: #061821;
    padding: 6px 10px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border: 2px solid #05496c;
    position: relative;
}
.page_released .div_show_select{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.page_released .div_delete{
    position: relative;
    top: 4px;
}
.no_data{
    text-align: center;
    font-style: italic;
    font-size: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.div_opt_alt{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
}
.space_div_icon {
    width: 24px;
    height: 24px;
}
.hide_data {
    background-color: #24394c;
    color: #ffffff;
}
.div_delete_data{
    display: flex;
    position: relative;
    top: 2px;
}
.icon_user{    
    width: auto;
    height: 26px;
    display: flex;
    cursor: pointer;
}
.div_align_center{
    text-align: center;
}
.div_cursor{
    cursor: pointer;
}
.div_show_btn{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.flex_direction{
    flex-direction: column;
}
.div_show_btn:nth-child(n + 2) {
    padding-top: 20px;
    margin-top: 18px;
    border-top: 2px solid #ebebeb;
}
.div_delete .icons{
    position: relative;
    top: 2px;
}
.inf_data_table{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.inf_data_table .data_table{
    width: auto;
    height: auto;
    color: #9BABC5;
    border: 1px solid #9BABC5;
    border-radius: 20px;
    padding: 10px;
    outline: none;
}
.show_img_td{
    width: auto;
    height: 30px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.div_border_dashed{
    width: 100%;
    height: auto;
    border: 2px dashed #cdd1d6;
    border-radius: 25px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.div_inf_topic{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}
.div_inf_topic .div_number_border{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 14px;
}
.div_inf_topic .div_number_border .number_border{
    font-size: 16px;
    font-weight: 600;
    position: relative;
    top: 2px;
}
.div_inf_layout{
    width: 100%;
    border-top: 2px solid #ebebeb;
    margin-top: 10px;
}
.list_service{
    width: calc(100% - 32px);
    border: 2px solid #ebebeb;
    padding: 16px;
    border-radius: 25px;
    margin-bottom: 20px !important;
}
.list_service_line_bottom{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    border-bottom: 2px solid #ebebeb;
    margin-bottom: 18px !important;
}
.list_service_line_bottom:nth-last-child(-n + 1){
    border-bottom: none;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}
.list_service_line_bottom:last-of-type .list_input_data:last-of-type{
    padding-bottom: 0px !important;
}


/* input */
.cnpj{
    width: 150px !important;
    text-align: center !important;
}
.customer_since{
    width: 100px !important;
    text-align: center !important;
}
.year_input{
    width: 60px !important;
    text-align: center !important;
}
.phone{
    width: 120px !important;
}
.tag{
    width: 80px !important;
    text-align: center !important;
}
.input_type_date, .discount{
    width: 130px !important;
}
.name_new_status{
    width: 220px !important;
}
.input_how_met{
    width: 244px !important;
    min-width: 244px !important;
}
.input_type_currency{    
    width: 114px !important;
    min-width: 114px !important;
}
.qtd_service{
    width: 40px !important;
    min-width: 40px !important;
}
.price_percentage{
    width: 78px !important;
    min-width: 78px !important;
}
.price_budget, .price_crm{
    width: 114px !important;
    min-width: 114px !important;
}
/* end */


/* scrollbar */
::-webkit-scrollbar {
    height: 6px;
    width: 4px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0px 14px #b7b7b7;
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0px 14px #111111;
}
/* end */


/* table */
table{
    padding-bottom: 10px;
    width: 100%;
}
table tr{
    background-color: #ffffff;
    border-radius: 25px;
}
table th{
    font-family: "Poppins";
    font-weight: 700;
    font-size: 12px;
    color: #ffffff;
    background-color: #061821;
    padding: 8px 16px;
}
table th:nth-child(1){
    border-top-left-radius: 25px;
}
table th:nth-last-child(-n + 1){
    border-top-right-radius: 25px;
}
table tr:nth-child(2n){
    background-color: #F6FAFF;
}
table td{
    padding: 16px;
    font-size: 12px;
}
table tr:last-of-type td:last-of-type{
    border-bottom-right-radius: 25px;
}
table tr:last-of-type td:first-of-type{
    border-bottom-left-radius: 25px;
}
/* end */


/* loading */
.return_data_save{
    position: absolute;
    left: 0;
    top: 0;
    background: #b3b3b36e;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
}
.div_loading{
    display: flex;
}
.loading{
    width: 60px;
    height: auto;
}
.return_data_save .dot-spinner {
    --uib-size: 2.8rem;
    --uib-speed: .9s;
    --uib-color: #183153;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
}
.return_data_save .number_loading{
    width: 600px;
    height: 28px;
    position: absolute;
    top: 50%;
    transform: translateY(50%);
    margin-top: 30px;
    border: 1px solid #000000;
    border-radius: 25px;
}
.return_data_save .number_loading .show_number{
    background: #ADFA06;
    border-radius: 25px;
    position: absolute;
    height: 28px;
    left: 0px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.return_data_save .number_loading .show_number span{
    padding-right: 10px;
    color: #000000;
}
.return_data_save .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}
.return_data_save .dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}
.return_data_save .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
}
.return_data_save .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
}
.return_data_save .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
}
.return_data_save .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
}
.return_data_save .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
}
.return_data_save .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
}
.return_data_save .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
}
.return_data_save .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
}
.return_data_save .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
}
.return_data_save .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
}
.return_data_save .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
}
.return_data_save .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
}
.return_data_save .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
}
.return_data_save .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
}
@keyframes pulse0112 {
    0%, 100% {
        transform: scale(0);
        opacity: 0.5;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
}
/* end */


/* popup */
.PopUp {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8000;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    background: #FFF;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 50%);
    font-family: 'Inter';
}
.PopUpCenter{
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .all {
    width: 800px;
    height: auto;
    display: flex;
    text-align: center;
    margin: 2em auto 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    overflow-y: hidden;
    position: relative;
    z-index: 30;
    /* border: 1px solid #005FA1; */
    margin-bottom: 40px;
    animation-name: popup_opacity;
    animation-duration: 0.5s;

    padding: 30px;
    border-radius: 8px;
}
@keyframes popup_opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.PopUp .icons_return{
    width: 300px;
    height: auto;
}
.PopUp .close_all{
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 20;
    top: 0;
}
.PopUp .all .div_data {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding-top: 20px;
}
.PopUp .all .type_div{
    display: flex;
    text-align: center;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0px 0px 16px;
    font-weight: bold;
    border-bottom:2px solid #D8D8D8;
    font-size: 18px;
    line-height: 18px;
    color: #000000;
}
.PopUp .all .div_data .content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.PopUp .all .div_data .content .title_stage{
    width: 100%;
    margin-top: 18px;
}
.PopUp .all .div_data .content .opt_select{
    flex-grow: 1;
    width: auto;
    padding: 6px 10px;
    cursor: pointer;
}
.PopUp .all .div_data .content .yes_update {
    background-color: #76c57e;
    border-radius: 25px;
}
.PopUp .all .div_data .content .not_update {
    background-color: #e95656;
    border-radius: 25px;
}
.PopUp .all .div_btn{
    width: 100%;
    margin-top: 6px;
}
.PopUp .all .div_btn button{
    width: auto;
    height: 40px;
    padding: 0px 30px;
    border: none;
    font-size: 14px;
    letter-spacing: 0.5px;
    cursor: pointer;
    border-radius: 20px;
}
/* end */


/* show_page_data */
.show_page_data{
    width: 100%;
    background-color: #ffffff;
    border-radius: 25px;
}
.show_page_data:nth-child(1n){
    margin-top: 20px;
}
.show_page_data .type_title{
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'inter';
    text-transform: uppercase;
}
.show_page_data .type_title .div_name_block{
    flex-grow: 1;
    padding: 10px 20px;
    background: #000000;
    color: #ffffff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.show_page_data .type_title .not_border_radius_right{
    border-top-right-radius: 0px;
}
.show_page_data .type_title .add_new_inf{
    padding: 10px 20px;
    cursor: pointer;
    background: #ADFA06; /* #b9a42f */
    color: #000000;
    border-top-right-radius: 25px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.show_page_data .list_input_data{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    padding: 0px 20px 20px;
}
.show_page_data .list_input_data:nth-child(n + 1){
    margin-bottom: 0;
}
.show_page_data .list_input_data:nth-last-child(-n + 1){
    margin-bottom: 0px;
}
.show_page_data .show_list_border{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.show_page_data .show_list_border:nth-child(1){
    border-top: none;
}
.show_page_data .show_list_border:nth-child(n + 3){
    border-top: 1px solid #08374f;
    padding-top: 16px;
    margin-top: 16px;
}
.show_page_data table {
    color: #6c757d;
    padding-bottom: 0px;
}
.show_page_data table th {
    color: #6c757d;
    background-color: #ffffff;
    border-bottom: 1px solid #e2e8f0;
    padding: 10px 16px;
    font-size: 14px;
}
.show_page_data table td {
    padding: 6px 16px;
    font-size: 14px;
}
.show_page_data table td .Comp_Select{
    margin-top: 0;
}
.show_page_data table td .Comp_Select .css-13cymwt-control{
    /* border: 2px solid #061821 !important; */
    border-radius: 0 !important;
}
.show_page_data table td .Comp_Select .css-fdbcdi-singleValue{
    border-radius: 0px;
    color: #061821 !important;
    border-radius: 0 !important;
}
.show_page_data table td .Comp_Select .css-t3ipsp-control{
    /* border: 2px solid #061821 !important; */
    border-radius: 0 !important;
}
.show_page_data table td .Comp_Select select,
.show_page_data table td .Comp_Select .css-13cymwt-control,
.show_page_data table td .Comp_Select .css-t3ipsp-control,
.show_page_data table td .Comp_Select .css-t3ipsp-control:hover {
    background-color: #ffffff;
    border-radius: 0 !important;
}
.show_page_data table td .Comp_Select .css-1xc3v61-indicatorContainer{
    color: #061821 !important;
    border-radius: 0 !important;
}
.show_page_data table td .Comp_Select .css-1u9des2-indicatorSeparator{
    background-color: #061821 !important;
    border-radius: 0 !important;
}
@media only screen and (max-width: 480px) {
    .show_page_data .type_title{
        font-size: 12px;
    }
}
/* end */


/* page_content */
.page_content{
    width: 100%;
    max-width: 1340px;
}
.page_content .Comp_Input .password{
    width: 120px;
}
.page_content .Comp_Select .access{
    min-width: 120px !important;
}
.page_content .Comp_Select .access_page{
    min-width: 170px !important;
}
.page_content .Comp_Select .type_page{
    min-width: 200px !important;
}
.page_content .Comp_Input .phone{
    width: 120px;
}
.page_content .Comp_Input .price{
    width: 80px;
    text-align: center;
}
.page_content .Comp_Input .new_payment{
    min-width: 230px;
}
.page_content .no_data{
    display: table-cell;
}
/* end */


/* portfolio */
.page_content .div_project{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    padding: 10px;
    width: -webkit-fill-available;
    height: auto;
    border: 2px solid #ebebeb;
}
.page_content .div_project .div_opt{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.page_content .div_project .div_opt div{
    display: flex;
}
.page_content .div_project .div_opt .opt_data{
    width: 80px;
    height: 80px;
    display: flex;
}

.page_content .div_project .type_layout{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.page_content .div_project .type_layout .opt_layout{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.page_content .div_project .type_layout .opt_layout .opt_selected{
    width: 24px;
    height: 24px;
    display: flex;
}
.page_content .div_project .type_layout .btn{
    padding: 10px 20px;
    background: #ADFA06;
    color: #000000;
    cursor: pointer;
    border-radius: 25px;
}

.page_content .div_project .show_layout{
    width: 100%;
}
.page_content .div_project .show_layout .list_input_data{
    padding: 0px 0px 0px 0px;
}
.page_content .div_project .show_layout .div_show_layout{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}
.page_content .div_project .show_layout .div_show_layout .list_input_data{
    align-items: flex-start;
}
.page_content .div_project .show_layout .div_show_layout .border_dashed{
    padding: 10px;
    border: 2px dashed #cdd1d6;
    border-radius: 25px;
}
.page_content .div_project .show_layout .div_show_layout .border_dashed_{
    width: 46%;
    flex-grow: 1;
    padding: 10px;
    border: 2px dashed #cdd1d6;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    gap: 8px;
}   
.page_content .div_project .show_layout .div_show_layout .height_1{
    height: 106px;
}
.page_content .div_project .show_layout .div_show_layout .height_2{
    height: 156px;
}
.page_content .div_project .show_layout .div_show_layout .height_3{
    height: 213px;
}
.page_content .div_project .show_layout .div_show_layout .height_4{
    height: 270px;
}
.page_content .div_project .show_layout .div_show_layout .height_5{
    height: 270px;
}
.page_content .div_project .show_layout .list_input_data .title_video{
    width: 300px !important;
}
.page_content .div_project .show_layout .div_show_layout .border_dashed_ .Comp_Input{
    flex-grow: 1;
}
.page_content .div_project .show_layout .div_show_layout .border_dashed_ .title_video{
    width: -webkit-fill-available !important;
}
/* end */


/* btn add */
.list_inf_click{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-bottom: 22px;
}
.div_btn_add{
    position: relative;
    top: 3px;
}
.div_btn_add .btn_add{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 41px;
    padding: 0px 20px;
    color: #000000;
    background-color: #ADFA06;
    border-radius: 25px;
    cursor: pointer;
}
.div_btn_add .opt_selected{
    text-decoration: underline;
}
.div_btn_add .show_opt_btn{
    background-color: transparent;
    border: 1px solid #ADFA06;
}
/* end */